/*
 |-----------------------------------------------------------------------------
 | components/molecules/RichText/RichText.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { find, get } from 'lodash';
import { TinaMarkdown } from 'tinacms/dist/rich-text';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import { fallback } from '@/utils/helpers';
import { organisationSelector } from '@/features/organisation';
import { pageSelector } from '@/features/page';
import { useAppSelector } from '@/app/hooks';

import * as IRichText from './types';

const RichText: FC<IRichText.IProps> = ({
	components,
	content,
	form,
	isCentered,
	isFullWidth,
	schema,
	tinaField,
	utilities,
}) => {
	// ray('Debug molecule RichText:', {
	// 	components: components,
	// 	content: content,
	// 	form: form,
	// 	isCentered: isCentered,
	// 	isFullWidth: isFullWidth,
	// 	schema: schema,
	// 	tinaField: tinaField,
	// 	utilities: utilities,
	// }).blue();

	const organisation = useAppSelector(organisationSelector);
	const page = useAppSelector(pageSelector);

	const Actions = ({ actions }) => {
		if (!actions) {
			return null;
		}

		const Actions = dynamic(() => import('@/molecules/Actions'));

		return <Actions actions={actions} utilities={`not-prose`} />;
	};

	const Callout = ({ text }) => {
		if (!text) {
			return null;
		}

		const Callout = dynamic(() => import('@/atoms/Callout'));

		return <Callout text={text} utilities={`not-prose`} />;
	};

	const Directives = ({ directives }) => {
		if (!directives) {
			return null;
		}

		const Directives = dynamic(() => import('@/molecules/Directives'));

		return <Directives directives={directives} utilities={`not-prose`} />;
	};

	const Form = ({ display }) => {
		if (!display) {
			return null;
		}

		const Form = dynamic(() => import('@/molecules/Form'));

		return (
			<Form
				action={get(form, 'action')}
				controls={get(form, 'controls')}
				title={get(form, 'title')}
				utilities={`not-prose`}
			/>
		);
	};

	const Lead = ({ text }) => {
		if (!text) {
			return null;
		}

		const Lead = dynamic(() => import('@/atoms/Lead'));

		return <Lead text={text} utilities={`not-prose`} />;
	};

	const Office = ({ id }) => {
		if (!id) {
			return null;
		}

		const Address = dynamic(() => import('@/molecules/Address'));
		const ContactPoint = dynamic(() => import('@/molecules/ContactPoint'));

		const address = find(get(organisation, 'addresses'), address => {
			return get(address, 'id') === id;
		});

		const contactPoint = find(
			get(organisation, 'contactPoints'),
			contactPoint => {
				return get(contactPoint, 'id') === id;
			}
		);

		return (
			<div className={`space-y-4`}>
				{address && (
					<Address
						country={get(address, 'country')}
						locality={get(address, 'locality')}
						postcode={get(address, 'postcode')}
						region={get(address, 'region')}
						street={get(address, 'street')}
						utilities={`not-prose`}
					/>
				)}

				{contactPoint && (
					<ContactPoint
						email={get(contactPoint, 'email')}
						hideLabels={true}
						phone={get(contactPoint, 'phone')}
						utilities={`list-none not-prose pl-0`}
					/>
				)}
			</div>
		);
	};

	const Quote = ({ cite, text }) => {
		if (!text) {
			return null;
		}

		const Quote = dynamic(() => import('@/atoms/Quote'));

		return <Quote text={text} title={cite} utilities={`not-prose`} />;
	};

	const Shots = ({ images }) => {
		if (!images) {
			return null;
		}

		const Shots = dynamic(() => import('@/molecules/Shots'));

		return <Shots images={images} utilities={`not-prose`} />;
	};

	const SocialMedia = ({ display }) => {
		if (!display) {
			return null;
		}

		const SocialMedia = dynamic(() => import('@/molecules/SocialMedia'));

		const platforms = get(organisation, 'platforms');

		return (
			platforms && (
				<SocialMedia
					platforms={platforms}
					utilities={'mt-8 not-prose'}
				/>
			)
		);
	};

	const Title = ({ text, utilities }) => {
		const Title = dynamic(() => import('@/atoms/Title'));

		return (
			<Title
				level={1}
				text={fallback(text, get(page, 'title'))}
				utilities={`
					font-normal
					font-primary
					not-prose
					text-2xl
					text-brand-4
					${utilities ? utilities : ''}
				`}
			/>
		);
	};

	return (
		<div
			className={`
				prose
				${isCentered ? 'mx-auto text-center' : ''}
				${isFullWidth ? 'max-w-none' : ''}
				${utilities ? utilities : null}
			`}
			data-testid="richText"
			data-tinafield={tinaField}
			itemProp={schema}
		>
			{components ? (
				<TinaMarkdown
					components={{
						Actions,
						Callout,
						Directives,
						Form,
						Lead,
						Office,
						Quote,
						Shots,
						SocialMedia,
						Title,
					}}
					content={content}
				/>
			) : (
				<TinaMarkdown content={content} />
			)}
		</div>
	);
};

export default RichText;
